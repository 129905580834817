<template>
  <Layout :loading="loading">
    <div class="services table-page">
      <div class="table-page__header">
        <h1 class="mb-2">Console Commands</h1>
      </div>
      <div>
        <b-card-group deck>
          <b-card
              class="mb-4"
              title="soa:sync"
          >
            <b-card-text>SOA database tables synchronization</b-card-text>
            <b-button href="#" variant="primary" @click="sendConsoleCommand('soa:sync')">Execute</b-button>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/router/layouts/main.vue';

export default {
  name: 'Commands',
  components: {
    Layout
  },
  data() {
    return {
      loading: false
    }
  },

  methods: {
    async sendConsoleCommand(command) {
      this.loading = true
      await this.$store.dispatch('settings/sendConsoleCommand', {'command': command})
      this.loading = false
    }
  },
}
</script>